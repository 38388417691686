import React from 'react';
//import logo from './assets/logo.svg';
import './css/App.css';

import Router from "./router";

function App() {
  return (
      <div id={"app"}>
        <Router />
      </div>
  );
}

export default App;
