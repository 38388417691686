import React from 'react';
import styled from "styled-components";

const PageNotFoundStyles = styled.header``;

const PageNotFound = () => {
    return (
        <PageNotFoundStyles>
            <header>
                <h1>404 Page Not Found</h1>
            </header>
        </PageNotFoundStyles>
    );
}

export default PageNotFound;