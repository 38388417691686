import React from "react";
import styled from "styled-components";

import Hero from "./Hero";

const HomeStyles = styled.article`
    
    `;

const Home = () => {
    return(
        <HomeStyles>
            <Hero />
        </HomeStyles>
    )
};

export default Home;