import React from "react";
import styled from "styled-components";

const AboutStyles = styled.header`
    
    `;

const About = () => {
    return(
        <AboutStyles>
            <h1>About</h1>
        </AboutStyles>
    )
};

export default About;