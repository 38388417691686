import React from "react";
import styled from "styled-components";

const PortfolioStyles = styled.header`
    
    `;

const Portfolio = () => {
    return(
        <PortfolioStyles>
            <h1>Portfolio</h1>
        </PortfolioStyles>
    )
};

export default Portfolio;