import React from "react";
import styled from "styled-components";

const FooterStyles = styled.header``;

const Footer = () => {
    return (
        <FooterStyles>
            <footer>
                &copy; Shingai Zindi | 2021
            </footer>
        </FooterStyles>
    );
}

export default Footer;