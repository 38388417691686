import React from "react";
import {BrowserRouter, Route, Switch} from "react-router-dom";

import NavBar from "./../components/navigation/NavBar";
import Footer from "../components/Footer";

//Pages
import About from "./../pages/About";
import Index from "../pages/Home";
import Portfolio from "../pages/Portfolio";

//404
import PageNotFound from "../pages/PageNotFound";

const Router = () => (
    <BrowserRouter>
        <NavBar/>
        <Switch>
            <Route exact path="/">
                <Index/>
            </Route>

            <Route path="/about">
                <About/>
            </Route>
            <Route path="/portfolio">
                <Portfolio/>
            </Route>

            <Route path="*">
                <PageNotFound/>
            </Route>
        </Switch>
        <Footer/>
    </BrowserRouter>
)

export default Router